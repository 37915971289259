// import { cloneDeep } from 'lodash';
import Vue from 'vue';

const state = Vue.observable({
  mobileWidth: 1080,
  windowWidth: 1080,
  isMobile: false,
  case_id: '',
  list: [],
  dependencies: {},
  linked_blocks: [],
  personal_details: null,
  gap_errors: null,
  gap_values: {},
  utils: {},
});

export const mutations = {
  handleGapErrors(payload) {
    console.log('handleGapErrors payload', payload); // TODO remove
    const { gaps, ...rest } = payload;
    // ? do we neew this? It leads to exceed quota errors:
    // let checkgapError = localStorage.getItem('gapErrorValue_'+payload.id);
    // console.log('checkgapError', checkgapError);
    // if (checkgapError == undefined || checkgapError || checkgapError == null) {
    //   localStorage.setItem('gapErrorValue_'+payload.id, JSON.stringify(payload));
    // }
    state.gap_errors = rest;
    this.setGapGroups(gaps);
  },
  setGapGroup(payload) {
    // Use 'block_id' prop as a group identifier
    // Use 'block_fe_id' as a particulat timeline identifier
    Vue.set(state.gap_values, payload.block_id, payload);
    // localStorage.setItem('gapTimeline_'+payload?.block_id, JSON.stringify(payload)); // ? do we neew this? It leads to exceed quota errors
  },
  setGapGroups(payload) {
    Object.entries(payload).forEach(([key, value]) => {
      Vue.set(state.gap_values, key, value);
      // localStorage.setItem('gapTimeline_'+key, JSON.stringify(value)); // ? do we neew this? It leads to exceed quota errors
    })
  },
  setFilledGap(field, block, gap, isValid = true) {
    // for filled gaps we will use values from related fields
    const newGap = {
      ...gap,
      frontend: true,
      block,
      field,
      invalid_gap: !isValid,
    }
    state.gap_values[block.block_id].timeline.push(newGap);
  },
  removeFilledGap(block) {
    const currentTimeline = state.gap_values[block.block_id].timeline;
    const idxToRemove = currentTimeline.findIndex((el) => el.block?.block_fe_id === block.block_fe_id);
    if (idxToRemove > -1) state.gap_values[block.block_id].timeline.splice(idxToRemove, 1);
  },

  /**
   * 
   * @param {*} sec_id uuid of the section, you want to change status of
   * @param {*} status status of the section, ERROR, PENDING, COMPLETED etc,.
   */
  setSectionStatus(sec_id, sectionStatus, statusProp = 'status') {
    let index = state.list.findIndex(el => el.section_id === sec_id)
    Vue.set(state.list, index, {...state.list[index], [statusProp]: sectionStatus})
  },

  /**
   * 
   * @param {Object} block , block  you want to re-order
   * @param {String} section_id , the section's id in which the block exists
   * @param {String} direction , can be up or down depending on the block's position in the block-group-list
   */
  handleOrder(block, section_id, direction) {
    // let currentSection = state.list.find(sec => sec.section_id === section_id)
    // currentSection.entities.splice()
    const moveInArray = function (arr, from, to) {
      // Make sure a valid array is provided
      if (Object.prototype.toString.call(arr) !== '[object Array]') {
        throw new Error('Please provide a valid array');
      }
      // Delete the item from it's current position
      var item = arr.splice(from, 1);
      // Make sure there's an item to move
      if (!item.length) {
        throw new Error('There is no item in the array at index ' + from);
      }
      // Move the item to its new position
      arr.splice(to, 0, item[0]);
      arr.forEach((item, index) => {
        item.serial = index + 1;
      });
    };

    // ! When you reassign a section/entities you lost all the references to its entities which are used for dependent fields, linked blocks, gap validation etc.
    // ! you shouldn't go through all the section to change one particular
    // state.list = state.list.map(sec => {
    //   if (sec.section_id === section_id ) {
    //     let blockEntities = cloneDeep(sec.entities)
    //     // ! change order here 'serial'
    //     const blockToOrderIndex = blockEntities.findIndex(blc => blc.block_fe_id === block.block_fe_id)
    //     const indexToMove = direction === 'up' ? blockToOrderIndex - 1 : blockToOrderIndex + 1
    //     blockEntities[blockToOrderIndex].serial = blockEntities[indexToMove].serial +  direction === 'up' ? -1 : 1
    //     blockEntities[indexToMove].serial = direction === 'up' ? -1 : 1
    //     moveInArray(blockEntities,indexToMove , blockToOrderIndex)
    //     return {...sec, entities: blockEntities}

    //   } else return sec
    // }) 
    const sectionToChange = state.list.find(sec => sec.section_id === section_id);
    const blockToOrderIndex = sectionToChange.entities.findIndex(blc => blc.block_fe_id === block.block_fe_id)
    const indexToMove = direction === 'up' ? blockToOrderIndex - 1 : blockToOrderIndex + 1
    sectionToChange.entities[blockToOrderIndex].serial = sectionToChange.entities[indexToMove].serial +  direction === 'up' ? -1 : 1
    moveInArray(sectionToChange.entities, indexToMove , blockToOrderIndex);
  },
  // markDuplicateFields(){
  //   const duplicateFields = {}
  //   state.list?.forEach((sec) => {
  //     let sectionHide = true;
  //     sec.entities.forEach((bl) => {
  //       if (bl.form_entity_type === "BLOCK") {
  //           // let blockHide = true;
  //           // bl.entities.forEach((field) => {
  //           //     if (field.is_hide_duplicate_field) {
  //           //         if (Object.keys(duplicateFields).includes(field.label)) {
  //           //             field.isDuplicate = true;
  //           //         }
  //           //         else {
  //           //             duplicateFields[field.label] = field.field_id;
  //           //             field.isDuplicate = false;
  //                       sectionHide = false;
  //           //             blockHide = false;
  //           //         }
  //           //     }
  //           //     else{sectionHide = false;blockHide = false;}
  //           // })
  //           // bl.blockHide = blockHide;
  //       }
  //       if (bl.form_entity_type === "FIELD") {
  //           if (bl.is_hide_duplicate_field) {
  //               if (Object.keys(duplicateFields).includes(bl.label)) {
  //                   bl.isDuplicate = true;
  //               }
  //               else {
  //                   duplicateFields[bl.label] = bl.field_id;
  //                   bl.isDuplicate = false;
  //                   sectionHide=false;
  //               }
  //           }
  //           else{sectionHide = false;}
  //       }
  //   })
  //   sec.sectionHide= sectionHide;
  // })}
}

export default state;
